/*
  When adding a new color make sure you add it to all three lists.
*/

export declare type DefaultColor = 'primary' | 'secondary' | 'tertiary'

export declare type RootColors = {
  backgroundGradientStart?: string;
  backgroundGradientEnd?: string;
  primary?: string;
  secondary?: string;
  tertiary?: string;
}

export declare type DefaultRootColors = {
  backgroundGradientStart: '#ffffff';
  backgroundGradientEnd: '#ffffff';
  primary: '#ffffff';
  secondary: '#232323';
  tertiary: '#ff3c00';
}

export const defaultRootColors: DefaultRootColors = {
  backgroundGradientStart: '#ffffff',
  backgroundGradientEnd: '#ffffff',
  primary: '#ffffff',
  secondary: '#232323',
  tertiary: '#ff3c00'
}
