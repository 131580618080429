/*
  When adding a new property make sure you add it to all three lists.
*/

import { MoaVersion } from './types'

export declare type RootProperties = {
  backgroundBlur?: number
  backgroundDarken?: number
  backgroundImage?: number
  backgroundImageCustom?: string
  bodyWebFont?: string
  defaultLanguage?: string
  enableDemoFeatures?: boolean
  favicons?: string[]
  webManifestTitle?: string
  redirectUrl?: string
  googleFont?: string
  gtmContainerId?: string[]
  moaVersion?: MoaVersion
  showBackgroundImage?: boolean
  showBorderRadius?: boolean
  borderRadius?: number
  useMaxWidth?: boolean
  maxWidth?: number
  paddingSm?: number
  paddingMd?: number
  paddingLg?: number
}

export declare type DefaultRootProperties = {
  backgroundBlur: 0.5
  backgroundDarken: 0.4
  backgroundImage: 21
  backgroundImageCustom: undefined
  bodyWebFont: 'sans-serif'
  defaultLanguage: 'en'
  enableDemoFeatures: false
  favicons: string[]
  webManifestTitle?: undefined
  redirectUrl?: undefined
  googleFont: undefined
  gtmContainerId: string[]
  moaVersion: 1
  showBackgroundImage: true
  showBorderRadius: true
  borderRadius: 8
  useMaxWidth: true
  maxWidth: 1920
  paddingSm: 0
  paddingMd: 0
  paddingLg: 0
}

export const defaultRootProperties: DefaultRootProperties = {
  backgroundBlur: 0.5,
  backgroundDarken: 0.4,
  backgroundImage: 21,
  backgroundImageCustom: undefined,
  bodyWebFont: 'sans-serif',
  defaultLanguage: 'en',
  enableDemoFeatures: false,
  favicons: [],
  webManifestTitle: undefined,
  redirectUrl: undefined,
  googleFont: undefined,
  gtmContainerId: [],
  moaVersion: 1,
  showBackgroundImage: true,
  showBorderRadius: true,
  borderRadius: 8,
  useMaxWidth: true,
  maxWidth: 1920,
  paddingSm: 0,
  paddingMd: 0,
  paddingLg: 0
}
