import { DefaultRootColors, defaultRootColors } from './rootColors'
import { DefaultRootProperties, defaultRootProperties } from './rootProperties'

export declare type ConfigDefaults = {
  properties: DefaultRootProperties,
  colors: DefaultRootColors,
  backgroundImageNumberCustom: 999
}

export const defaults: ConfigDefaults = {
  properties: defaultRootProperties,
  colors: defaultRootColors,
  backgroundImageNumberCustom: 999
}
