import { debug } from 'debug'

import { configClientGraphQL, gql } from '@olyslager/api-client'
import { getCustomerName } from '@olyslager/global-utilities'

import { ConfigResult } from './types'
const debugGetConfig = debug('customer-configuration:getConfig')

export const getConfig = async (): Promise<ConfigResult> => {
  const customer = getCustomerName()
  debugGetConfig(`Getting configuration for customer "${customer}"`)
  if (customer === null) {
    debugGetConfig(`Failed to get configuration for customer "${customer}", no customer name`)
    return {
      siteConfiguration: null,
      siteDataConfiguration: null
    }
  } else {
    const query = gql`
      query getSiteConfiguration($siteId: String!)
      {
        siteConfiguration: siteconfiguration(siteId: $siteId)
        {
          properties
          colors
          translations
          referrerSecurity {
            enabled
            allowList
            denyList
            allowListHosts
            deniedRedirectUrl
          }
          frontends {
            name
            enabled
            properties
            colors
          }
        }
        siteDataConfiguration(siteId: $siteId) {
          defaultDatasetCode
          countryMap {
            datasetCode
            countries
          }
        }
      }
    `

    const data = await configClientGraphQL.get<ConfigResult>(query, { siteId: customer })
    debugGetConfig('Configuration: %O', data.data)
    if (data.data) {
      const configResult: ConfigResult = {
        siteConfiguration: data.data.siteConfiguration,
        siteDataConfiguration: data.data.siteDataConfiguration
      }

      if (configResult.siteConfiguration) {
        configResult.siteConfiguration.properties = configResult.siteConfiguration.properties || {}
        configResult.siteConfiguration.colors = configResult.siteConfiguration.colors || {}
        configResult.siteConfiguration.frontends?.forEach((frontend) => {
          frontend.properties = frontend.properties || {}
          frontend.colors = frontend.colors || {}
        })
      }

      return configResult
    } else if (data.errors) {
      debugGetConfig('Failed to get configuration: %O', data.errors[0].message)
      return {
        siteConfiguration: null,
        siteDataConfiguration: null,
        error: {
          name: 'Exception',
          message: data.errors[0].message
        }
      }
    }
  }
  return {
    siteConfiguration: null,
    siteDataConfiguration: null,
    error: {
      name: 'Exception',
      message: 'Unknown error occured when trying to get config'
    }
  }
}
