/*
  When adding a new property make sure you add it to all three lists.
*/

export declare type RootProperties = {
  backgroundBlur?: number;
  backgroundDarken?: number;
  backgroundImage?: number;
  backgroundImageCustom?: string;
  bodyWebFont?: string;
  defaultLanguage?: string;
  enableDemoFeatures?: boolean;
  favicons?: string[];
  webManifestTitle?: string;
  googleFont?: string;
  gtmContainerId?: string[];
  moaVersion?: 1 | 2;
  showBackgroundImage?: boolean;
  showBorderRadius?: boolean;
  borderRadius?: number;
}

export declare type DefaultRootProperties = {
  backgroundBlur: 0.5;
  backgroundDarken: 0.4;
  backgroundImage: 21;
  backgroundImageCustom: undefined;
  bodyWebFont: 'sans-serif';
  defaultLanguage: 'en';
  enableDemoFeatures: false;
  favicons: string[];
  webManifestTitle?: undefined;
  googleFont: undefined;
  gtmContainerId: string[];
  moaVersion: 1;
  showBackgroundImage: true;
  showBorderRadius: true,
  borderRadius: 8;
}

export const defaultRootProperties: DefaultRootProperties = {
  backgroundBlur: 0.5,
  backgroundDarken: 0.4,
  backgroundImage: 21,
  backgroundImageCustom: undefined,
  bodyWebFont: 'sans-serif',
  defaultLanguage: 'en',
  enableDemoFeatures: false,
  favicons: [],
  webManifestTitle: undefined,
  googleFont: undefined,
  gtmContainerId: [],
  moaVersion: 1,
  showBackgroundImage: true,
  showBorderRadius: true,
  borderRadius: 8
}
